import React from 'react';
import './Disclaimer.scss';
// import Donation from './Donation';

export default function Disclaimer() {
    return (
        <div className="disclaimer">
            <a href="mailto:samuelbdantas@gmail.com">Samuel B. Dantas</a>
            {/* <span className="separator">&nbsp;-&nbsp;</span>
            <Donation /> */}
        </div>
    );
}
