import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'firebase/auth';
import './App.scss';
import routes from './types/route.config';

export default function App() {
    return (
        <div className="app-content">
            <Router>
                <Switch>
                    {routes.map((route) => (
                        <Route
                            key={route.path}
                            path={route.path}
                            exact={route.exact}
                            component={route.component}
                        />
                    ))}
                </Switch>
            </Router>
            <div className="border top" />
            <div className="border left" />
            <div className="border top-left" />
            <div className="border top-right" />
            <div className="border bottom-left" />
            <div className="border bottom-right" />
            <div className="border right" />
            <div className="border bottom" />
        </div>
    );
}
