import React, { useCallback, useEffect, useState } from 'react';
import Button from '../common/Button';
import useAudio from '../hooks/useAudio';
import './MeaningInput.scss';
import { useFirebaseContext } from '../types/FirebaseContext';
import Textarea from '../common/Textarea';

export default function MeaningInput(props: Props) {
    const { playerId, gameId, word } = props;
    const {
        setWordMeaning,
    } = useFirebaseContext();
    const [meaning, setMeaning] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const playNotification = useAudio('/notification.mp3');

    useEffect(() => {
        playNotification();
    }, []);

    const handleConfirmClick = useCallback(async () => {
        const meaningLength = meaning?.trim()?.length ?? 0;

        if (meaningLength <= 3) {
            setErrorMessage('Digite um significado falso válido!');
            return;
        }

        setErrorMessage('');

        setIsLoading(true);
        await setWordMeaning(playerId, gameId, meaning);
    }, [meaning]);

    return (
        <div className="meaning-input">
            <div className="subtitle">
                A palavra é
            </div>

            <div className="word">
                {word}
            </div>

            <div className="fields">
                <div className="field">
                    <div className="label">
                        Seu significado falso:
                    </div>

                    <Textarea
                        placeholder="Digite o seu significado falso da palavra"
                        value={meaning}
                        onChange={(e) => setMeaning(e)}
                    />
                </div>

                {!!errorMessage && (
                    <div className="error-message">
                        {errorMessage}
                    </div>
                )}

                <div className="field">
                    <Button onClick={handleConfirmClick} disabled={isLoading}>
                        CONFIRMAR
                    </Button>
                </div>
            </div>
        </div>
    );
}

interface Props {
    playerId: string;
    gameId: string;
    word: string;
}
