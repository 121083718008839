import { ComponentType } from 'react';
import Game from '../game/Game';
import MainMenu from '../main-menu/MainMenu';
import GameMenu from '../game-menu/GameMenu';

const routes: RouteConfig[] = [
    {
        path: '/',
        component: MainMenu,
        exact: true,
    },
    {
        path: '/game-menu',
        component: GameMenu,
    },
    {
        path: '/game',
        component: Game,
    },
];

export interface RouteConfig {
    path: string;
    component: ComponentType;
    exact?: boolean;
}

export default routes;
