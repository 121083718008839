import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../common/Button';
import Disclaimer from '../common/Disclaimer';
import useLocationQuery from '../hooks/useLocationQuery';
import './MainMenu.scss';

export default function MainMenu() {
    const query = useLocationQuery();
    const reload = query.get('reload') === 'true';
    const history = useHistory();

    if (reload) {
        history.replace('/');
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    }

    return (
        <div className="main-menu">
            <div className="title">
                Dicionário Falso
            </div>

            {/* @ts-ignore */}
            {window.REACT_APP_VERSION}

            <div className="buttons">
                <Button onClick={() => history.push('/game-menu?newGame=true')}>
                    CRIAR NOVO JOGO
                </Button>

                <Button onClick={() => history.push('/game-menu?newGame=false')}>
                    ENTRAR EM UM JOGO
                </Button>
            </div>

            <Disclaimer />
        </div>
    );
}
