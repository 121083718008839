import classNames from 'classnames';
import React, { ReactNode } from 'react';
import './Button.scss';

export default function Button({
    disabled = false,
    selected = false,
    children,
    onClick,
}: Props) {
    return (
        <button
            type="button"
            className={classNames('button', { 'mod-selected': selected, 'mod-disabled': disabled })}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
            <div className={classNames('button-border left', { 'mod-disabled': disabled })} />
            <div className={classNames('button-border right', { 'mod-disabled': disabled })} />
        </button>
    );
}

interface Props {
    disabled?: boolean;
    selected?: boolean;
    children: ReactNode;
    onClick: () => void;
}
