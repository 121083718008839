import React from 'react';
import './WaitStatus.scss';

export default function WaitStatus({ title, messages = [] }: Props) {
    return (
        <div className="wait-status">
            <img className="wait-icon" alt="Tempo" src="../../assets/time.png" />

            <div className="title">
                {title}
            </div>

            {messages.map((m) => (
                <div key={m} className="message">
                    {m}
                </div>
            ))}
        </div>
    );
}

interface Props {
    title: string;
    messages?: string[];
}
