import React from 'react';
import './Textarea.scss';

export default function Textarea({
    id,
    placeholder,
    value,
    maxLength = 256,
    onChange,
}: Props) {
    return (
        <div className="textarea">
            <textarea
                id={id}
                placeholder={placeholder}
                value={value}
                maxLength={maxLength}
                onChange={(e) => onChange(e.target.value)}
            />
            <div className="textarea-border top" />
            <div className="textarea-border top-left" />
            <div className="textarea-border left" />
            <div className="textarea-border top-right" />
            <div className="textarea-border right" />
        </div>
    );
}

interface Props {
    id?: string;
    placeholder?: string;
    value: string;
    maxLength?: number;
    // eslint-disable-next-line no-unused-vars
    onChange: (text: string) => void;
}
