import React, { useCallback, useState } from 'react';
import Button from '../common/Button';
import { GameData, PlayerData } from '../types/game.types';
import './Lobby.scss';
import { useFirebaseContext } from '../types/FirebaseContext';

const MIN_ROUNDS = 5;
const MAX_ROUNDS = 40;

export default function Lobby({
    game, player, players = [], onAbandonClick,
}: Props) {
    const [totalRounds, setTotalRounds] = useState(MIN_ROUNDS);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const { startGame } = useFirebaseContext();
    const isOwner = game.ownerId === player.id;

    const handleStartGame = useCallback(async () => {
        if (players.length < 3) {
            setErrorMessage('O jogo só pode começar com pelo menos 3 jogadores!');
            return;
        }

        setErrorMessage('');

        setIsLoading(true);
        await startGame(game.gameId, totalRounds, 0, players);
    }, [game, players, totalRounds]);

    const handleAbandonGame = useCallback(() => {
        setIsLoading(true);
        onAbandonClick();
    }, [onAbandonClick]);

    const handleChangeRounds = useCallback((value: number) => {
        const modifiedRounds = totalRounds + value;
        setTotalRounds(Math.min(Math.max(MIN_ROUNDS, modifiedRounds), MAX_ROUNDS));
    }, [totalRounds]);

    return (
        <div className="lobby">
            <img className="wait-icon" alt="Tempo" src="../../assets/time.png" />

            <div className="title">
                Código desse jogo: <span className="game-code">{game.gameId}</span>
            </div>

            <div className="subtitle">
                {players.length} {players.length === 1 ? 'jogador conectado' : 'jogadores conectados'}
            </div>

            {players.map((p) => (
                <div key={p.id} className="player">
                    {p.name}
                </div>
            ))}

            {!!errorMessage && (
                <div className="error-message">
                    {errorMessage}
                </div>
            )}

            <div className="fields">
                {isOwner ? (
                    <>
                        <div className="field">
                            <div className="subtitle">
                                Quantidade de rodadas
                            </div>

                            <div className="config">
                                <Button onClick={() => handleChangeRounds(-1)}>
                                    &minus;
                                </Button>

                                <div className="counter">
                                    {totalRounds}
                                </div>

                                <Button onClick={() => handleChangeRounds(1)}>
                                    &#43;
                                </Button>
                            </div>
                        </div>

                        <div className="field">
                            <Button onClick={handleStartGame} disabled={isLoading}>
                                INICIAR JOGO
                            </Button>
                        </div>
                    </>
                ) : (
                    <div className="subtitle">
                        Aguardando o jogo começar...
                    </div>
                )}

                <div className="field">
                    <Button onClick={handleAbandonGame} disabled={isLoading}>
                        {isOwner ? 'CANCELAR JOGO' : 'ABANDONAR JOGO'}
                    </Button>
                </div>
            </div>
        </div>
    );
}

interface Props {
    game: GameData;
    player: PlayerData;
    players: PlayerData[];
    onAbandonClick: () => void;
}
