import React, { useCallback, useEffect, useState } from 'react';
import Button from '../common/Button';
import TextInput from '../common/TextInput';
import useAudio from '../hooks/useAudio';
import './WordInput.scss';
import { useFirebaseContext } from '../types/FirebaseContext';
import { GameData, PlayerData } from '../types/game.types';
import Textarea from '../common/Textarea';

export default function WordInput(props: Props) {
    const { player, game, allPlayers } = props;
    const {
        setCurrentWord,
        skipTurn,
    } = useFirebaseContext();
    const [meaning, setMeaning] = useState<string>('');
    const [word, setWord] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const playNotification = useAudio('/notification.mp3');

    useEffect(() => {
        playNotification();
    }, []);

    const handleSkipClick = useCallback(async () => {
        setIsLoading(true);
        await skipTurn(game, allPlayers);
    }, [word, meaning]);

    const handleConfirmClick = useCallback(async () => {
        const wordLength = word?.trim()?.length ?? 0;
        const meaningLength = meaning?.trim()?.length ?? 0;

        if (wordLength <= 3) {
            setErrorMessage('Digite uma palavra válida!');
            return;
        }

        if (meaningLength <= 5) {
            setErrorMessage('Digite um significado válido!');
            return;
        }

        setIsLoading(true);
        setErrorMessage('');

        await setCurrentWord(player.id, game.gameId, word, meaning);
    }, [word, meaning]);

    return (
        <div className="word-input">
            <div className="title">
                {`Sua vez, ${player.name}!`}
            </div>

            <div className="fields">
                <div className="field">
                    <div className="label">
                        Palavra:
                    </div>

                    <TextInput placeholder="Digite a palavra" value={word} onChange={setWord} maxLength={40} />
                </div>

                <div className="field">
                    <div className="label">
                        Significado correto:
                    </div>

                    <Textarea
                        placeholder="Digite o significado correto da palavra"
                        value={meaning}
                        onChange={(e) => setMeaning(e)}
                    />
                </div>

                {!!errorMessage && (
                    <div className="error-message">
                        {errorMessage}
                    </div>
                )}

                <div className="field">
                    <Button onClick={handleConfirmClick} disabled={isLoading}>
                        CONFIRMAR
                    </Button>
                </div>

                <div className="field">
                    <Button onClick={handleSkipClick} disabled={isLoading}>
                        PULAR A VEZ
                    </Button>
                </div>
            </div>
        </div>
    );
}

interface Props {
    player: PlayerData;
    game: GameData;
    allPlayers: PlayerData[];
}
