import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';
import { FirebaseProvider } from './types/FirebaseContext';
import { initializeApp } from 'firebase/app';
import { browserLocalPersistence, getAuth, setPersistence } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: 'AIzaSyCD2VwWs40l1QpKP5eMeOTzOBd-affYAhI',
    authDomain: 'dicionariofalso.firebaseapp.com',
    databaseURL: 'https://dicionariofalso-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'dicionariofalso',
    storageBucket: 'dicionariofalso.appspot.com',
    messagingSenderId: '890598495723',
    appId: '1:890598495723:web:fdc94c257bc6ae702d75ae',
    measurementId: 'G-SN948HNPXJ',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);

setPersistence(auth, browserLocalPersistence);

ReactDOM.render(
    <React.StrictMode>
        <FirebaseProvider auth={auth} database={database}>
            <App />
        </FirebaseProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
