import {
    useEffect, useState, useCallback, useMemo,
} from 'react';

export default function useAudio(url: string) {
    const audio = useMemo(() => new Audio(url), []);
    const [playing, setPlaying] = useState(false);

    const toggle = useCallback(() => {
        setPlaying(!playing);
    }, [playing]);

    useEffect(() => {
        if (playing) {
            audio.play();
        } else {
            audio.pause();
        }
    }, [playing]);

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, []);

    return toggle;
}
