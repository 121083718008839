/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export interface GameData {
    gameId: string;
    ownerId: string;
    currentPlayerId?: string;
    state: GameState;
    currentOrderIndex?: number;
    word?: string;
    currentRound?: number;
    totalRounds?: number;
    waitSeconds?: number;
    playerOrder?: string[];
}

export interface PlayerData {
    id: string;
    gameId: string;
    name: string;
    score: number;
    hasAbandoned: boolean;
    turnIndex: number | null;
}

export interface MeaningData {
    playerId: string;
    optionNumber?: number;
    meaning: string;
    isCorrect: boolean;
    normalVotePlayerIds?: string[];
    creativeVotePlayerIds?: string[];
}

export const enum GameState {
    LOBBY = 'lobby',
    NEW_WORD = 'new-word',
    NEW_MEANING = 'new-meaning',
    MEANING_VOTE = 'meaning-vote',
    ROUND_RESULTS = 'round-results',
    FINISHED = 'finished',
}
