import React, { useEffect } from 'react';
import useAudio from '../hooks/useAudio';
import { MeaningData } from '../types/game.types';
import './MeaningList.scss';
import { getUniqueArray } from '../utils/generalUtils';

export default function MeaningList({ word, meanings = [], voteMessages = [] }: Props) {
    const playNotification = useAudio('/notification.mp3');

    useEffect(() => {
        playNotification();
    }, []);

    return (
        <div className="meaning-list">
            <div className="label">
                Leia a palavra e os significados para os outros jogadores!
            </div>

            <div className="word">
                {word}
            </div>

            {getUniqueArray(meanings, 'playerId').sort((a, b) => a.optionNumber! - b.optionNumber!).map((m) => (
                <div key={m.optionNumber} className="answer-container">
                    <div className="answer-number">{m.optionNumber}</div>
                    {m.meaning}
                    {m.isCorrect && (
                        <div className="correct" />
                    )}

                    <div className="answer-container-border top" />
                    <div className="answer-container-border top-left" />
                    <div className="answer-container-border left" />
                    <div className="answer-container-border top-right" />
                    <div className="answer-container-border right" />
                </div>
            ))}

            <div className="subtitle">
                Votos
            </div>

            {voteMessages.map((m) => (
                <div key={m} className="player">
                    {m}
                </div>
            ))}
        </div>
    );
}

interface Props {
    word: string;
    meanings: MeaningData[];
    voteMessages: string[];
}
