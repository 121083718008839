import React, { ReactNode } from 'react';
import './Finished.scss';
import { GameData, PlayerData } from '../types/game.types';
import Button from '../common/Button';
import { useHistory } from 'react-router-dom';

function getPlayerPositionIcon(position: number): ReactNode {
    if (position === 1) {
        return <div className="medal first" />;
    }

    if (position === 2) {
        return <div className="medal second" />;
    }

    if (position === 3) {
        return <div className="medal third" />;
    }

    return `${position} - `;
}

export default function Finished(props: Props) {
    const { game, allPlayers } = props;
    const history = useHistory();

    return (
        <div className="finished">
            <div className="title">
                Dicionário Falso
            </div>

            <div className="subtitle">
                Jogo {game.gameId} encerrado
            </div>

            <img className="icon" alt="Estrela" src="../../assets/prize.png" />

            <div className="content">
                {allPlayers
                    .sort((a, b) => b.score - a.score)
                    .slice(0, 3)
                    .map((p, i) => (
                        <div key={p.id} className={`player${i === 0 ? ' first' : ''}`}>
                            {getPlayerPositionIcon(i + 1)}{p.name}<span className="points">{p.score} pontos</span>
                        </div>
                    ))}
            </div>

            <Button onClick={() => history.push('/')}>
                VOLTAR PRO MENU
            </Button>
        </div>
    );
}

interface Props {
    game: GameData;
    allPlayers: PlayerData[];
}
