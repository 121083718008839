/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { GameData, GameState, MeaningData, PlayerData } from '../types/game.types';
import './Game.scss';
import Lobby from './Lobby';
import MeaningInput from './MeaningInput';
import WaitStatus from './WaitStatus';
import WordInput from './WordInput';
import { useListVals, useObjectVal } from 'react-firebase-hooks/database';
import { equalTo, orderByChild, query, ref } from 'firebase/database';
import { dbPaths, useFirebaseContext } from '../types/FirebaseContext';
import { useAuthState } from 'react-firebase-hooks/auth';
import useLocationQuery from '../hooks/useLocationQuery';
import { signOut } from 'firebase/auth';
import { getUniqueArray } from '../utils/generalUtils';
import {
    getCurrentlyPlayingPlayer,
    getPlayerNameById,
    getVoters,
    hasEveryoneVoted,
    isPlayerTurn,
} from '../utils/gameUtils';
import MeaningList from './MeaningList';
import MeaningVote from './MeaningVote';
import RoundResult from './RoundResult';
import Finished from './Finished';

export default function Game() {
    const locationQuery = useLocationQuery();
    const gameId = locationQuery.get('gameId');
    const history = useHistory();
    const {
        auth,
        database,
        abandonGame,
        validateAllMeanings,
        calculateScore,
    } = useFirebaseContext();
    const [user] = useAuthState(auth);
    const [game] = useObjectVal<GameData>(ref(database, dbPaths.game(gameId)));
    const [meanings] = useListVals<MeaningData>(ref(database, dbPaths.meanings(gameId)));
    const [player] = useObjectVal<PlayerData>(ref(database, dbPaths.player(user?.uid ?? '')));
    const allPlayersQuery = query(ref(database, dbPaths.players()), orderByChild('gameId'), equalTo(gameId));
    const [allPlayers] = useListVals<PlayerData>(allPlayersQuery);
    const isRequestPending = useRef(false);

    const isLoading = !user || !game || !player || !allPlayers || !meanings;
    const isGameFinished = game?.state === GameState.FINISHED;

    useEffect(() => {
        if (isLoading || game?.ownerId !== player?.id) {
            return;
        }

        if (meanings?.length === game?.playerOrder?.length
            && game?.state === GameState.NEW_MEANING
            && !isRequestPending.current
        ) {
            isRequestPending.current = true;
            validateAllMeanings(game!, () => { isRequestPending.current = false; });
        }

        if (game?.state === GameState.MEANING_VOTE
            && hasEveryoneVoted(game, meanings!)
            && !isRequestPending.current
        ) {
            isRequestPending.current = true;
            calculateScore(
                game, allPlayers, meanings, () => { isRequestPending.current = false; },
            );
        }
    }, [game, meanings, isLoading]);

    const handleAbandonGame = useCallback(async () => {
        if (player) {
            abandonGame(player.id);
            signOut(auth);
            history.push('/');
        }
    }, [player]);

    const gameStateMemo = useMemo(() => {
        if (isLoading) {
            return <WaitStatus title="Carregando..." />;
        }

        if (player && player.gameId !== gameId) {
            return <WaitStatus title="Jogo inválido!" />;
        }

        const isCurrentPlayerTurn = isPlayerTurn(game, player);
        const uniquePlayers = getUniqueArray(allPlayers ?? [], 'id');

        switch (game.state) {
        case GameState.LOBBY: {
            return (
                <Lobby
                    player={player!}
                    game={game}
                    players={uniquePlayers}
                    onAbandonClick={handleAbandonGame}
                />
            );
        }
        case GameState.NEW_WORD: {
            const currentPlayerName = getCurrentlyPlayingPlayer(game, uniquePlayers)?.name;

            return isCurrentPlayerTurn ? (
                <WordInput player={player} game={game} allPlayers={uniquePlayers} />
            ) : (
                <WaitStatus
                    title={`Aguardando ${currentPlayerName} escolher uma palavra ou pular!`}
                />
            );
        }
        case GameState.NEW_MEANING: {
            const meaningAlreadySent = !!meanings?.length && meanings.some((m) => m.playerId === player.id);

            if (isCurrentPlayerTurn || meaningAlreadySent) {
                const messages = getUniqueArray(meanings?.filter((m) => m.playerId !== game.currentPlayerId), 'playerId')
                    .map((m) => `${getPlayerNameById(player, uniquePlayers, m.playerId)} já enviou!`) || [];

                if (!messages.length) {
                    messages.push('Ninguém enviou um significado falso ainda!');
                }

                return (
                    <WaitStatus
                        title="Aguardando significados falsos!"
                        messages={messages}
                    />
                );
            }

            return <MeaningInput playerId={player!.id} gameId={game.gameId} word={game.word!} />;
        }
        case GameState.MEANING_VOTE: {
            const voters = getVoters(meanings);
            const messages = voters.map((id) => `${getPlayerNameById(player, uniquePlayers, id)} já votou!`) || [];

            if (!messages.length) {
                messages.push('Ninguém votou ainda!');
            }

            const voteAlreadySent = voters.length && voters.some((id) => id === player?.id);

            if (voteAlreadySent) {
                return (
                    <WaitStatus
                        title="Aguardando votos!"
                        messages={messages}
                    />
                );
            }

            if (isCurrentPlayerTurn) {
                return (
                    <MeaningList
                        meanings={meanings}
                        voteMessages={messages}
                        word={game.word!}
                    />
                );
            }

            return <MeaningVote playerId={player!.id} game={game} meanings={meanings} />;
        }

        case GameState.ROUND_RESULTS: {
            return (
                <RoundResult
                    isPlayerTurn={isCurrentPlayerTurn}
                    isOwner={game.ownerId === player.id}
                    meanings={meanings}
                    game={game}
                    allPlayers={uniquePlayers}
                />
            );
        }

        case GameState.FINISHED: {
            return <Finished game={game} allPlayers={uniquePlayers} />;
        }

        default:
            return null;
        }
    }, [game, player, allPlayers, meanings, isLoading]);

    return (
        <div className="game">
            {gameStateMemo}
            {!!game?.currentRound && !!game?.totalRounds && !isGameFinished && (
                <div className="game-status">
                    Rodada {game.currentRound} / {game.totalRounds}
                </div>
            )}
        </div>
    );
}
