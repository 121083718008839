import { GameData, MeaningData, PlayerData } from '../types/game.types';

export const getPlayerNameById = (player: PlayerData, allPlayers: PlayerData[], id: string) =>
    (id === player?.id ? 'Você' : allPlayers?.find((p) => p.id === id)?.name);

export const isPlayerTurn = (game: GameData, player: PlayerData) =>
    game && player && game.playerOrder?.[game.currentOrderIndex!] === player.id;

export const getCurrentlyPlayingPlayer = (game: GameData, allPlayers: PlayerData[]) =>
    allPlayers.find((p) => p.id === game.playerOrder![game.currentOrderIndex!]);

export const getNextPlayerOrder = (game: GameData, allPlayers: PlayerData[]) => {
    let currentOrderIndex = game.currentOrderIndex! >= allPlayers.length - 1
        ? 0 : game.currentOrderIndex! + 1;
    let nextPlayerId = game.playerOrder![currentOrderIndex];

    // eslint-disable-next-line no-loop-func
    while (allPlayers.find((p) => p.id === nextPlayerId && p.hasAbandoned)) {
        currentOrderIndex = currentOrderIndex >= allPlayers.length - 1
            ? 0 : game.currentOrderIndex! + 1;
        nextPlayerId = game.playerOrder![currentOrderIndex];
    }

    return {
        currentOrderIndex,
        nextPlayerId,
    };
};

export const getVoters = (meanings: MeaningData[]): string[] => {
    const voters: Record<string, number> = {};

    meanings.forEach((meaning) => {
        (meaning.normalVotePlayerIds ?? []).forEach((id) => {
            voters[id] = (voters[id] ?? 0) + 1;
        });

        (meaning.creativeVotePlayerIds ?? []).forEach((id) => {
            voters[id] = (voters[id] ?? 0) + 1;
        });
    });

    return Object.entries(voters)
        .filter(([, count]) => count === 2)
        .map(([playerId]) => playerId);
};

export const getPlayerIdByMeaningOptionNumber = (
    optionNumber: number, meanings: MeaningData[],
): string | undefined =>
    meanings.find((m) => m.optionNumber === optionNumber)?.playerId;

export const hasEveryoneVoted = (game: GameData, meanings: MeaningData[]): boolean =>
    getVoters(meanings).length === game.playerOrder!.length - 1;

export const getMostCreativePlayers = (meanings: MeaningData[], game: GameData): string[] => {
    const creativeCount: Record<string, number> = {};

    game.playerOrder?.forEach((id) => {
        creativeCount[id] = 0;
    });

    meanings.forEach((meaning) => {
        meaning.creativeVotePlayerIds?.forEach(() => {
            creativeCount[meaning.playerId] += 1;
        });
    });

    const maxCreativeVotes = Object.values(creativeCount).sort((a, b) => b - a)[0];

    return Object.entries(creativeCount)
        .filter(([, count]) => count === maxCreativeVotes)
        .map(([playerId]) => playerId);
};
