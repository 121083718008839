import { customAlphabet } from 'nanoid';

const GAME_ID_LENGTH = 5;

export const generateGameId = () => {
    const nanoId = customAlphabet('0123456789ABDCDEFGHIJKLMNOPQRSTUVWXYZ', GAME_ID_LENGTH);
    return nanoId();
};

export const isValidGameId = (gameId?: string) => gameId?.trim().length === GAME_ID_LENGTH;

export function getUniqueArray<T>(input: T[], id: keyof T): T[] {
    const output: T[] = [];

    input.forEach((e) => {
        if (!output.some((oe) => oe[id] === e[id])) {
            output.push(e);
        }
    });

    return output;
}

export function shuffleArray(input: any[]): any[] {
    const output = [...input];
    let currentIndex = input.length;
    let randomIndex;

    while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        [output[currentIndex], output[randomIndex]] = [
            output[randomIndex], output[currentIndex]];
    }

    return output;
}
