import React from 'react';
import './TextInput.scss';

export default function TextInput({
    id,
    placeholder,
    value,
    maxLength = 15,
    onChange,
}: Props) {
    return (
        <div className="text-input">
            <input
                id={id}
                placeholder={placeholder}
                type="text"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                maxLength={maxLength}
            />
            <div className="input-border top" />
            <div className="input-border top-left" />
            <div className="input-border left" />
            <div className="input-border top-right" />
            <div className="input-border right" />
        </div>
    );
}

interface Props {
    id?: string;
    placeholder?: string;
    value: string;
    maxLength?: number;
    // eslint-disable-next-line no-unused-vars
    onChange: (text: string) => void;
}
