import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import Button from '../common/Button';
import useAudio from '../hooks/useAudio';
import './MeaningVote.scss';
import { useFirebaseContext } from '../types/FirebaseContext';
import { GameData, MeaningData } from '../types/game.types';
import { getPlayerIdByMeaningOptionNumber } from '../utils/gameUtils';

export default function MeaningVote({ game, playerId, meanings }: Props) {
    const { setPlayerVotes } = useFirebaseContext();
    const [normalSelectedOption, setNormalSelectionOption] = useState<number>(0);
    const [creativeSelectedOption, setCreativeSelectionOption] = useState<number>(0);
    const options = useMemo(
        () => [...Array(meanings.length + 1).keys()].slice(1),
        [meanings.length],
    );
    const [isLoading, setIsLoading] = useState(false);
    const playNotification = useAudio('/notification.mp3');

    useEffect(() => {
        playNotification();
    }, []);

    const handleVoteClick = useCallback(async () => {
        setIsLoading(true);

        const normalVotePlayerId = getPlayerIdByMeaningOptionNumber(normalSelectedOption, meanings);
        const creativeVotePlayerId = getPlayerIdByMeaningOptionNumber(
            creativeSelectedOption, meanings,
        );

        await setPlayerVotes(game.gameId, playerId, normalVotePlayerId!, creativeVotePlayerId!);
    }, [normalSelectedOption, creativeSelectedOption, meanings]);

    return (
        <div className="meaning-vote">
            <div className="subtitle">
                A palavra é:
            </div>

            <div className="word">
                {game.word}
            </div>

            <div className="fields">
                <div className="label">
                    Escolha a opção correta:
                </div>

                <div className="vote-options">
                    {options.map((o) => (
                        <div key={o} className="button-wrapper">
                            <Button
                                disabled={
                                    isLoading
                                    || playerId === getPlayerIdByMeaningOptionNumber(o, meanings)
                                }
                                selected={normalSelectedOption === o}
                                onClick={() => setNormalSelectionOption(o)}
                            >
                                {o}
                            </Button>
                        </div>
                    ))}
                </div>

                <div className="label">
                    Escolha a opção mais criativa:
                </div>

                <div className="vote-options">
                    {options.map((o) => (
                        <div key={o} className="button-wrapper">
                            <Button
                                disabled={
                                    isLoading
                                    || playerId === getPlayerIdByMeaningOptionNumber(o, meanings)
                                }
                                selected={creativeSelectedOption === o}
                                onClick={() => setCreativeSelectionOption(o)}
                            >
                                {o}
                            </Button>
                        </div>
                    ))}
                </div>

                <Button
                    disabled={!normalSelectedOption || !creativeSelectedOption || isLoading}
                    onClick={handleVoteClick}
                >
                    CONFIRMAR VOTO
                </Button>
            </div>
        </div>
    );
}

interface Props {
    playerId: string;
    game: GameData;
    meanings: MeaningData[];
}
