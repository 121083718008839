import React, {
    createContext, ReactNode, useContext, useEffect, useMemo,
} from 'react';
import { Auth, signInAnonymously } from 'firebase/auth';
import {
    Database, update, query, ref, set, get, remove,
} from 'firebase/database';
import { GameData, GameState, MeaningData, PlayerData } from './game.types';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getNextPlayerOrder } from '../utils/gameUtils';
import { shuffleArray } from '../utils/generalUtils';

interface FirebaseContextProps {
    auth: Auth;
    database: Database;
    createPlayer: (player: PlayerData) => Promise<void>;
    abandonGame: (playerId: string) => Promise<void>;
    createGame: (game: GameData) => Promise<void>;
    updateGame: (gameId: string, game: Partial<GameData>) => Promise<void>;
    startGame: (
        gameId: string, totalRounds: number, waitSeconds: number, players: PlayerData[],
    ) => Promise<void>;
    findGame: (gameId: string) => Promise<GameData | null>;
    setCurrentWord: (
        playerId: string, gameId: string, word: string, meaning: string
    ) => Promise<void>;
    setWordMeaning: (playerId: string, gameId: string, meaning: string) => Promise<void>;
    skipTurn: (game: GameData, allPlayers: PlayerData[]) => Promise<void>;
    validateAllMeanings: (game: GameData, onFinish: () => void) => void;
    setPlayerVotes: (
        gameId: string,
        playerId: string,
        normalMeaningPlayerId: string,
        creativeMeaningPlayerId: string,
    ) => Promise<void>;
    calculateScore: (
        game: GameData, allPlayers: PlayerData[], meanings: MeaningData[], onFinish: () => void,
    ) => void;
    nextRound: (game: GameData, allPlayers: PlayerData[]) => Promise<void>;
    finishGame: (gameId: string) => Promise<void>;
}

export const FirebaseContext = createContext({} as FirebaseContextProps);

export const dbPaths = {
    game: (gameId: string) => `games/${gameId}`,
    player: (playerId: string) => `players/${playerId}`,
    players: () => 'players',
    meanings: (gameId: string, playerId?: string) => `meanings/${gameId}${playerId ? `/${playerId}` : ''}`,
};

export function FirebaseProvider(props: FirebaseProviderProps) {
    const {
        auth, database, children,
    } = props;
    const [user] = useAuthState(auth);

    useEffect(() => {
        if (!user) {
            signInAnonymously(auth);
        }
    }, [user]);

    const contextData: FirebaseContextProps = useMemo(() => {
        async function createPlayer(player: PlayerData) {
            const playerRef = ref(database, dbPaths.player(player.id));
            await set(playerRef, player);
        }

        async function abandonGame(playerId: string) {
            const playerRef = ref(database, dbPaths.player(playerId));
            await update(playerRef, {
                hasAbandoned: true,
            });
        }

        async function createGame(game: GameData) {
            const gameRef = ref(database, dbPaths.game(game.gameId));
            await set(gameRef, game);
        }

        async function updateGame(gameId: string, game: Partial<GameData>) {
            const gameRef = ref(database, dbPaths.game(gameId));
            await update(gameRef, game);
        }

        async function startGame(
            gameId: string, totalRounds: number, waitSeconds: number, players: PlayerData[],
        ) {
            const playerOrder = shuffleArray(players.map((p) => p.id));

            await updateGame(gameId, {
                totalRounds,
                waitSeconds,
                state: GameState.NEW_WORD,
                currentOrderIndex: 0,
                currentPlayerId: playerOrder[0],
                currentRound: 1,
                playerOrder,
            });
        }

        async function findGame(gameId: string): Promise<GameData | null> {
            const gamesQuery = query(ref(database, dbPaths.game(gameId)));
            try {
                return (await get(gamesQuery)).val() as GameData;
            } catch (e) {
                return null;
            }
        }

        async function setCurrentWord(
            playerId: string, gameId: string, word: string, meaning: string,
        ) {
            const gameRef = ref(database, dbPaths.game(gameId));
            const meaningRef = ref(database, dbPaths.meanings(gameId, playerId));

            await set(meaningRef, {
                playerId,
                meaning,
                isCorrect: true,
            });

            await update(gameRef, { word, state: GameState.NEW_MEANING });
        }

        async function setWordMeaning(
            playerId: string, gameId: string, meaning: string,
        ) {
            const meaningPlayerRef = ref(database, dbPaths.meanings(gameId, playerId));

            await set(meaningPlayerRef, {
                playerId,
                meaning,
                isCorrect: false,
            });
        }

        async function validateAllMeanings(game: GameData, onFinish: () => void) {
            const meaningGameRef = ref(database, dbPaths.meanings(game.gameId));
            const gameRef = ref(database, dbPaths.game(game.gameId));
            const allMeanings = (await get(meaningGameRef))
                .val() as { playerId: string, meaning: MeaningData}[];

            if (allMeanings && Object.keys(allMeanings).length === game.playerOrder!.length) {
                const shuffledMeanings = shuffleArray([...Object.entries(allMeanings)]);

                const meaningUpdates = [];

                for (let i = 0; i < shuffledMeanings.length; i += 1) {
                    const playerId = shuffledMeanings[i][0];
                    const meaningRef = ref(database, dbPaths.meanings(game.gameId, playerId));
                    meaningUpdates.push(update(meaningRef, { optionNumber: i + 1 }));
                }

                await Promise.all(meaningUpdates);
                await update(gameRef, { state: GameState.MEANING_VOTE });
                onFinish();
            }
        }

        async function skipTurn(game: GameData, allPlayers: PlayerData[]) {
            const { currentOrderIndex, nextPlayerId } = getNextPlayerOrder(game, allPlayers);

            await updateGame(game.gameId, {
                currentOrderIndex,
                currentPlayerId: nextPlayerId,
            });
        }

        async function setPlayerVotes(
            gameId: string,
            currentPlayerId: string,
            normalMeaningPlayerId: string,
            creativeMeaningPlayerId: string,
        ) {
            const normalMeaningRef = ref(database, dbPaths.meanings(gameId, normalMeaningPlayerId));
            const creativeMeaningRef = ref(
                database, dbPaths.meanings(gameId, creativeMeaningPlayerId),
            );

            const normalMeaning = (await get(normalMeaningRef)).val() as MeaningData;
            await update(normalMeaningRef, {
                normalVotePlayerIds: [
                    ...(normalMeaning.normalVotePlayerIds ?? []),
                    currentPlayerId,
                ],
            });

            const creativeMeaning = (await get(creativeMeaningRef)).val() as MeaningData;
            await update(creativeMeaningRef, {
                creativeVotePlayerIds: [
                    ...(creativeMeaning.creativeVotePlayerIds ?? []),
                    currentPlayerId,
                ],
            });
        }

        async function calculateScore(
            game: GameData, allPlayers: PlayerData[], meanings: MeaningData[], onFinish: () => void,
        ) {
            const gameRef = ref(database, dbPaths.game(game.gameId));
            const roundScore: Record<string, number> = {};
            const creativeCount: Record<string, number> = {};

            game.playerOrder?.forEach((id) => {
                roundScore[id] = 0;
                creativeCount[id] = 0;
            });

            meanings.forEach((meaning) => {
                const playerWhoWrote = meaning.playerId;

                meaning.normalVotePlayerIds?.forEach((playerWhoVoted) => {
                    if (meaning.isCorrect) {
                        roundScore[playerWhoVoted] += 20;
                    } else {
                        roundScore[playerWhoWrote] += 10;
                    }
                });

                meaning.creativeVotePlayerIds?.forEach(() => {
                    creativeCount[meaning.playerId] += 1;
                });
            });

            const maxCreativeVotes = Object.values(creativeCount).sort((a, b) => b - a)[0];

            // Users with most creative votes gets points
            Object.entries(creativeCount)
                .filter(([, count]) => count === maxCreativeVotes)
                .forEach(([playerId]) => {
                    roundScore[playerId] += 1;
                });

            const playerUpdates: Promise<void>[] = [];

            Object.entries(roundScore)
                .filter(([, newScore]) => newScore > 0)
                .forEach(([playerId, newScore]) => {
                    const playerRef = ref(database, dbPaths.player(playerId));
                    const player = allPlayers.find((p) => p.id === playerId);

                    playerUpdates.push(
                        update(playerRef, {
                            score: (player?.score ?? 0) + newScore,
                        }),
                    );
                });

            await Promise.all(playerUpdates);
            await update(gameRef, { state: GameState.ROUND_RESULTS });
            onFinish();
        }

        async function nextRound(game: GameData, allPlayers: PlayerData[]) {
            const { currentOrderIndex, nextPlayerId } = getNextPlayerOrder(game, allPlayers);
            const meaningRef = ref(database, dbPaths.meanings(game.gameId));

            await remove(meaningRef);
            await updateGame(game.gameId, {
                state: GameState.NEW_WORD,
                currentRound: game.currentRound! + 1,
                currentOrderIndex,
                currentPlayerId: nextPlayerId,
                // @ts-ignore
                word: null,
            });
        }

        async function finishGame(gameId: string) {
            const meaningRef = ref(database, dbPaths.meanings(gameId));
            await remove(meaningRef);
            await updateGame(gameId, {
                state: GameState.FINISHED,
            });
        }

        return {
            auth,
            database,
            createPlayer,
            abandonGame,
            createGame,
            findGame,
            updateGame,
            startGame,
            setCurrentWord,
            setWordMeaning,
            skipTurn,
            validateAllMeanings,
            setPlayerVotes,
            calculateScore,
            nextRound,
            finishGame,
        };
    }, []);

    return (
        <FirebaseContext.Provider value={contextData}>
            {children}
        </FirebaseContext.Provider>
    );
}

interface FirebaseProviderProps {
    auth: Auth;
    database: Database;
    children: ReactNode;
}

export function useFirebaseContext() {
    const context = useContext(FirebaseContext);

    if (context == null) {
        throw new Error('FirebaseContext provider not found!');
    }

    return context;
}
