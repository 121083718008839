import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useLocalStorage from 'use-local-storage';
import Button from '../common/Button';
import Loading from '../common/Loading';
import TextInput from '../common/TextInput';
import useLocationQuery from '../hooks/useLocationQuery';
import './GameMenu.scss';
import { generateGameId, isValidGameId } from '../utils/generalUtils';
import { useFirebaseContext } from '../types/FirebaseContext';
import { useAuthState } from 'react-firebase-hooks/auth';
import { GameState } from '../types/game.types';

export default function GameMenu() {
    const {
        auth,
        createPlayer,
        createGame,
        findGame,
    } = useFirebaseContext();
    const [user] = useAuthState(auth);
    const locationQuery = useLocationQuery();
    const newGame = locationQuery.get('newGame') === 'true';
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [playerName, setPlayerName] = useLocalStorage('playerName', '');
    const [gameCode, setGameCode] = useLocalStorage('gameCode', '');

    const handleConfirmClick = useCallback(async () => {
        if (!user) {
            return;
        }

        if (playerName?.trim().length <= 2) {
            setErrorMessage('Nome inválido!');
            return;
        }

        if (!newGame && !isValidGameId(gameCode)) {
            setErrorMessage('Código de jogo inválido!');
            return;
        }

        setErrorMessage('');
        setIsLoading(true);

        const playerId = user.uid;
        const validGameCode = gameCode?.trim();
        const newGameId = generateGameId();

        if (!newGame) {
            const gameData = await findGame(validGameCode);

            if (!gameData || gameData.state !== GameState.LOBBY) {
                setIsLoading(false);
                setErrorMessage('Jogo não encontrado ou já iniciado!');
                return;
            }
        }

        if (newGame) {
            await createGame({
                gameId: newGameId,
                ownerId: playerId,
                state: GameState.LOBBY,
            });
        }

        const playerGameId = newGame ? newGameId : validGameCode;

        await createPlayer({
            id: playerId,
            gameId: playerGameId,
            name: playerName,
            score: 0,
            turnIndex: null,
            hasAbandoned: false,
        });

        history.push(`/game?gameId=${playerGameId}`);
    }, [user, playerName, gameCode, newGame]);

    const subtitle = newGame ? 'Criar um novo jogo' : 'Entrar num jogo';

    return (
        <div className="new-game">
            <div className="title">
                Dicionário Falso
            </div>

            <div className="subtitle">
                {isLoading ? 'Conectando...' : subtitle}
            </div>

            {isLoading && (
                <div className="loading">
                    <Loading />
                </div>
            )}

            {!isLoading && (
                <div className="fields">
                    <div className="field">
                        <div className="label">
                            Nome do jogador:
                        </div>

                        <TextInput
                            placeholder="Digite o seu nome"
                            value={playerName}
                            onChange={setPlayerName}
                            maxLength={20}
                        />
                    </div>

                    {newGame}

                    {!newGame && (
                        <div className="field">
                            <div className="label">
                                Código do jogo:
                            </div>

                            <TextInput
                                placeholder="Digite o código do jogo"
                                value={gameCode}
                                onChange={(t) => setGameCode(t.toUpperCase())}
                                maxLength={5}
                            />
                        </div>
                    )}

                    {!!errorMessage && (
                        <div className="error-message">
                            {errorMessage}
                        </div>
                    )}

                    <div className="field">
                        <Button onClick={handleConfirmClick}>
                            CONFIRMAR
                        </Button>
                    </div>

                    <div className="field">
                        <Button onClick={() => history.goBack()}>
                            VOLTAR
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
}
