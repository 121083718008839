import React, {
    ReactNode,
    useCallback, useEffect, useMemo, useState,
} from 'react';
import Button from '../common/Button';
import useAudio from '../hooks/useAudio';
import { GameData, MeaningData, PlayerData } from '../types/game.types';
import './RoundResult.scss';
import { getMostCreativePlayers } from '../utils/gameUtils';
import { useFirebaseContext } from '../types/FirebaseContext';

function getPlayerPositionIcon(position: number): ReactNode {
    if (position === 1) {
        return <div className="medal first" />;
    }

    if (position === 2) {
        return <div className="medal second" />;
    }

    if (position === 3) {
        return <div className="medal third" />;
    }

    return `${position} - `;
}

export default function RoundResult({
    isPlayerTurn, isOwner, game, meanings = [], allPlayers = [],
}: Props) {
    const {
        nextRound,
        finishGame,
    } = useFirebaseContext();
    const [isLoading, setIsLoading] = useState(false);
    const playNotification = useAudio('/notification.mp3');

    const handleNextClick = useCallback(async () => {
        setIsLoading(true);
        await nextRound(game, allPlayers);
    }, [game, allPlayers]);

    const handleFinishClick = useCallback(async () => {
        setIsLoading(true);
        await finishGame(game.gameId);
    }, [game]);

    const creativePlayers = useMemo(() =>
        getMostCreativePlayers(meanings, game), [meanings, game],
    );

    useEffect(() => {
        playNotification();
    }, []);

    const isFinalRound = game.currentRound === game.totalRounds;

    return (
        <div className="round-result">
            {isFinalRound && (
                <>
                    <div className="subtitle">
                        PONTUAÇÃO FINAL
                    </div>

                    {allPlayers.sort((a, b) => b.score - a.score).map((p, i) => (
                        <div key={p.id} className="player">
                            {getPlayerPositionIcon(i + 1)}{p.name}<span className="points">{p.score} pontos</span>
                        </div>
                    ))}
                </>
            )}

            <div className="subtitle">
                Resultado da rodada
            </div>

            <div className="word">
                {game.word}
            </div>

            {meanings.sort((a, b) => a.optionNumber! - b.optionNumber!).map((m) => (
                <div key={m.optionNumber} className="answer-container">
                    <div className="answer-number">{m.optionNumber}</div>
                    {m.meaning}
                    {m.isCorrect && (
                        <div className="correct" />
                    )}
                    {creativePlayers.includes(m.playerId) && (
                        <div className="creative" />
                    )}
                    {allPlayers.filter((p) => m.normalVotePlayerIds?.includes(p.id)).map((p) => (
                        <div key={p.id} className="player-vote">
                            {p.name}
                        </div>
                    ))}

                    <div className="answer-container-border top" />
                    <div className="answer-container-border top-left" />
                    <div className="answer-container-border left" />
                    <div className="answer-container-border top-right" />
                    <div className="answer-container-border right" />
                </div>
            ))}

            {!isFinalRound && (
                <>
                    <div className="subtitle">
                        Pontuação atual
                    </div>

                    {allPlayers.sort((a, b) => b.score - a.score).map((p, i) => (
                        <div key={p.id} className="player">
                            {getPlayerPositionIcon(i + 1)}{p.name}<span className="points">{p.score} pontos</span>
                        </div>
                    ))}
                </>
            )}

            {isPlayerTurn && !isFinalRound && (
                <Button onClick={handleNextClick} disabled={isLoading}>
                    INICIAR A PRÓXIMA RODADA
                </Button>
            )}

            {isOwner && isFinalRound && (
                <Button onClick={handleFinishClick} disabled={isLoading}>
                    FINALIZAR O JOGO
                </Button>
            )}

        </div>
    );
}

interface Props {
    isPlayerTurn: boolean;
    isOwner: boolean;
    game: GameData;
    meanings: MeaningData[];
    allPlayers: PlayerData[];
}
